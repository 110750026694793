body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
  background-color: grey;
}

.single-qubit-gate {
  position: relative;
  font-size: 25px;
  font-weight: bold;
  cursor: move;
  width: min(80%,60px);
  height: min(80%,60px);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 2px solid black;
  background: white;
}

.control {
  position: relative;
  font-size: 25px;
  cursor: move;
  z-index: 1;
}

.circle{
  border:2px solid #000;
  width:20px;
  height:20px;
  border-radius:100%;
  position:relative;
  margin:5px;
  display:inline-block;
  vertical-align:middle;
}

.fill {
  background-color: black;
}

.tooltip-text {
  display: none;
  top: 105%;
  white-space: nowrap;
  background-color: black;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  z-index: 1;
}

.single-qubit-gate:hover .tooltip-text, .control:hover .tooltip-text {
  display: flex;
}

.trash {
  position: relative;
  font-size: 40px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
}

.left .tooltip-text {
  left: 105%;
}

.right .tooltip-text {
  right: 105%;
}

.trash:hover .tooltip-text {
  display: flex;
}

.square {
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wire:before, .top-wire:before, .bottom-wire:before {
  content: '';
  position: absolute;
}

.wire:before {
  top: 50%;
  left: 0;
  border-top: 2px solid black;
  width: 100%;
}

.top-wire:before, .bottom-wire:before {
  left: 50%;
  border-left: 2px solid black;
  height: calc(50% + 12px);
  transform: translateX(-1px);
}

.top-wire:before {
  top: 0;
}

.bottom-wire:before {
  top: calc(50% - 10px);
}

.button {
  position: fixed;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
